import * as React from "react"
import {Redirect, RouteComponentProps} from "react-router"
import BasePage, {BasePageProps} from "../BasePage"
import Checkbox from "../../components/Checkbox"
import {PlayerState, PlayerStateDto} from "../../api/dtos"
import {getPlayerState, wannaJoinOrQuit} from "../../api/api"
import {Link} from "react-router-dom"
import {mobile} from "../../App"
import FullPageSpinner from "../../components/FullPageSpinner"
import {LinkButton} from "../../components/LinkButton"

interface State {
    errorMessage?: string
    redirectTo?: string
    fatalError: boolean
    loading: boolean
    playerState?: PlayerState
    groupPlayName: string
}

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string, linkId: string }>

export default class WannaJoinOrQuitPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            fatalError: false,
            loading: true,
            groupPlayName: ""
        }
    }

    async componentDidMount() {
        const {groupplayId} = this.props.match.params
        if (this.props.session) {
            try {
                const playerState: PlayerStateDto = await getPlayerState(groupplayId, this.props.session.sessionId)
                this.setState({
                    playerState: playerState.state,
                    groupPlayName: playerState.groupplayName
                })
            } catch (error: any) {
                console.log('ERROR:' + error.type)
                switch (error.type) {
                    default:
                        this.setState({fatalError: true})
                        break
                }
            }
        }
        this.setState({loading: false})
    }

    render() {
        const {groupplayId} = this.props.match.params
        const fontSize = mobile ? "3rem" : "1rem"
        const width = mobile ? "90vw" : "20rem"
        const height = mobile ? "52rem" : "15rem"
        const buttonWidth = mobile ? "20rem" : "10rem"
        if (this.state.redirectTo) {
            return (<Redirect to={this.state.redirectTo}/>)
        }
        if (!this.props.session) {
            return (
                <BasePage {...this.props} loading={this.state.loading} fatalError={this.state.fatalError}>
                    <div style={{
                        margin: "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        height: height,
                        position: "relative",
                        fontSize: fontSize
                    }}>
                        <p style={{textAlign: "center", width: width, margin: "auto"}}>
                            Om du vill anmäla dig att vara med i gruppspelet måste du
                            först logga in på gruppspelet.se.
                        </p>
                        <p style={{textAlign: "center", width: width, margin: "auto"}}>
                            Har du inget konto? <Link to="/newAccount">Klicka här!</Link>
                        </p>
                        <svg version="1.1" id="arrow" style={{
                            position: "absolute", right: mobile ? "6rem" : "-10rem", top: "1rem",
                            width: "5rem", height: "5rem", fill: "white"
                        }} x="0px" y="0px" viewBox="0 0 288.824 288.824">
                            <g>
                                <path id="Arrow_Up_Right" d="M3.501,285.323c4.668,4.668,12.235,4.668,16.891,0L264.763,40.951v91.382
		c0,6.641,5.39,12.03,12.03,12.03c6.641,0,12.03-5.39,12.03-12.03V12.03c0-3.188-1.239-6.268-3.501-8.529S279.981,0,276.793,0
		H156.49c-6.641,0-12.03,5.39-12.03,12.03s5.39,12.03,12.03,12.03h91.382L3.501,268.432C-1.167,273.1-1.167,280.667,3.501,285.323z"
                                />
                            </g>
                        </svg>
                    </div>
                </BasePage>
            )
        } else {
            const playerState = this.state.playerState
            if (playerState === PlayerState.PLAYING || playerState === PlayerState.WANNA_PAUSE
                || playerState === PlayerState.WANNA_QUIT) {
                //playing player might wanna quit/pause
                return (
                    <BasePage {...this.props} loading={this.state.loading} fatalError={this.state.fatalError}>
                        <div style={{
                            margin: "auto",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-evenly",
                            height: height,
                            fontSize: fontSize
                        }}>
                            <p style={{textAlign: "center", width: width, margin: "auto"}}>
                                Jag, {this.props.session.firstName} {this.props.session.lastName}, vill från och
                                med nästa omgång i {this.state.groupPlayName}:
                            </p>
                            <div style={{margin: "auto"}}>
                                <div style={{display: "flex", alignItems: "center", marginBottom: "0.5rem"}}>
                                    <span style={{
                                        width: mobile ? "24rem" : "8rem",
                                        textAlign: "end",
                                        marginRight: "1rem"
                                    }}>Fortsätta spela:</span>
                                    <Checkbox checked={playerState === PlayerState.PLAYING}
                                              onChange={() => this.setState({playerState: PlayerState.PLAYING})}
                                              id={"wannaJoin"}/>
                                </div>
                                <div style={{display: "flex", alignItems: "center", marginBottom: "0.5rem"}}>
                                    <span style={{
                                        width: mobile ? "24rem" : "8rem",
                                        textAlign: "end",
                                        marginRight: "1rem"
                                    }}>Pausa:</span>
                                    <Checkbox checked={playerState === PlayerState.WANNA_PAUSE}
                                              onChange={() => this.setState({playerState: PlayerState.WANNA_PAUSE})}
                                              id={"dontWannaJoin"}/>
                                </div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <span style={{
                                        width: mobile ? "24rem" : "8rem",
                                        textAlign: "end",
                                        marginRight: "1rem"
                                    }}>Hoppa av helt:</span>
                                    <Checkbox checked={playerState === PlayerState.WANNA_QUIT}
                                              onChange={() => this.setState({playerState: PlayerState.WANNA_QUIT})}
                                              id={"dontWannaJoin"}/>
                                </div>
                            </div>
                            <button style={{width: buttonWidth, margin: "auto"}} onClick={this.handleSaveClick}
                                    disabled={this.saveDisabled()}>Spara
                            </button>
                            <LinkButton style={{fontSize: fontSize, textAlign: "center"}}
                                        onClick={() => this.setState({redirectTo: "/gruppspel/" + groupplayId})}/>
                        </div>
                    </BasePage>
                )
            }
            if (playerState === PlayerState.WANNA_JOIN_AGAIN || playerState === PlayerState.PAUSE) {
                //old paused player might wanna join again
                return (
                    <BasePage {...this.props} loading={this.state.loading} fatalError={this.state.fatalError}>
                        <div style={{
                            margin: "auto",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-evenly",
                            height: height,
                            fontSize: fontSize
                        }}>
                            <p style={{textAlign: "center", width: width, margin: "auto"}}>
                                Jag, {this.props.session.firstName} {this.props.session.lastName}, vill vara med i
                                i {this.state.groupPlayName} från och med nästa omgång.
                            </p>
                            <div style={{margin: "auto"}}>
                                <div style={{display: "flex", alignItems: "center", marginBottom: "0.5rem"}}>
                                    <span style={{
                                        width: mobile ? "28rem" : "9rem",
                                        textAlign: "end",
                                        marginRight: "1rem"
                                    }}>Ja:</span>
                                    <Checkbox checked={playerState === PlayerState.WANNA_JOIN_AGAIN}
                                              onChange={() => this.setState({playerState: PlayerState.WANNA_JOIN_AGAIN})}
                                              id={"wannaJoin"}/>
                                </div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <span style={{
                                        width: mobile ? "28rem" : "9rem",
                                        textAlign: "end",
                                        marginRight: "1rem"
                                    }}>Nej, fortsätt pausa:</span>
                                    <Checkbox checked={playerState === PlayerState.PAUSE}
                                              onChange={() => this.setState({playerState: PlayerState.PAUSE})}
                                              id={"dontWannaJoin"}/>
                                </div>
                            </div>
                            <button style={{width: buttonWidth, margin: "auto"}} onClick={this.handleSaveClick}
                                    disabled={this.saveDisabled()}>Spara
                            </button>
                            <LinkButton style={{fontSize: fontSize, textAlign: "center"}}
                                        onClick={() => this.setState({redirectTo: "/gruppspel/" + groupplayId})}/>
                        </div>
                    </BasePage>
                )
            }
            if (playerState === PlayerState.NONE || playerState === PlayerState.DONT_WANNA_JOIN || playerState === PlayerState.WANNA_JOIN) {
                //new player might wanna to join..
                return (
                    <BasePage {...this.props} loading={this.state.loading} fatalError={this.state.fatalError}>
                        <div style={{
                            margin: "auto",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-evenly",
                            height: height,
                            fontSize: fontSize
                        }}>
                            <p style={{textAlign: "center", width: width, margin: "auto"}}>
                                Jag, {this.props.session.firstName} {this.props.session.lastName}, vill vara med i
                                i {this.state.groupPlayName} från och med nästa omgång.
                            </p>
                            <div style={{margin: "auto"}}>
                                <div style={{display: "flex", alignItems: "center", marginBottom: "0.5rem"}}>
                                    <span style={{width: mobile ? "6rem" : "2rem", textAlign: "end", marginRight: "1rem"}}>Ja:</span>
                                    <Checkbox checked={playerState === PlayerState.WANNA_JOIN}
                                              onChange={() => this.setState({playerState: PlayerState.WANNA_JOIN})}
                                              id={"wannaJoin"}/>
                                </div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <span style={{
                                        width: mobile ? "6rem" : "2rem",
                                        textAlign: "end",
                                        marginRight: "1rem"
                                    }}>Nej:</span>
                                    <Checkbox checked={playerState === PlayerState.DONT_WANNA_JOIN}
                                              onChange={() => this.setState({playerState: PlayerState.DONT_WANNA_JOIN})}
                                              id={"dontWannaJoin"}/>
                                </div>
                            </div>
                            <button style={{width: buttonWidth, margin: "auto"}} onClick={this.handleSaveClick}
                                    disabled={this.saveDisabled()}>Spara
                            </button>
                            <LinkButton style={{fontSize: fontSize, textAlign: "center"}}
                                        onClick={() => this.setState({redirectTo: "/gruppspel/" + groupplayId})}/>
                        </div>
                    </BasePage>
                )
            }
        }
        return <FullPageSpinner/>
    }

    private saveDisabled() {
        return this.state.playerState === PlayerState.NONE
    }

    private handleSaveClick = async () => {
        const {groupplayId} = this.props.match.params
        const state = this.state.playerState
        const session = this.props.session
        if (session && state) {
            this.setState({loading: true})
            try {
                await wannaJoinOrQuit(groupplayId, state, session.sessionId)
                this.setState({redirectTo: "/gruppspel/" + groupplayId + "/table"})
            } catch (error: any) {
                console.log('ERROR' + error)
                this.setState({fatalError: true})
            }
        } else {
            this.setState({fatalError: true})
        }
    }
}