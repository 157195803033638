import * as React from "react"
import {NasmoConfigDto} from "../../../api/dtos"
import TextInput from "../../../components/TextInput"
import {mobile} from "../../../App"

interface Props {
    nasmo: NasmoConfigDto
    onChange: (nasmo : NasmoConfigDto) => any
}

interface State {
}

export default class NasmoConfig extends React.Component<Props, State> {

    render() {
        const {nasmo} = this.props;
        const fontSize = mobile ? "3rem" : "1rem";
        const textMargin = mobile ? "1.5rem" : "0.5rem";
        return (

                <div style={{display: "flex", flexDirection: "column", marginTop: "2rem"}}>
                    <span style={{fontSize: fontSize, marginLeft: textMargin}}>System id:</span>
                    <TextInput value={nasmo.systemId} onChange={this.handleSystemIdChange}
                               placeHolder={"System id"}/>
                    <span style={{fontSize: fontSize, marginLeft: textMargin}}>Bokningskontots namn:</span>
                    <TextInput value={nasmo.bookingUsername} onChange={this.handleBookingsUsernameChange}
                               placeHolder={"Bokningskonto"}/>
                    <span style={{fontSize: fontSize, marginLeft: textMargin}}>Admin e-post:</span>
                    <TextInput value={nasmo.adminUsername} onChange={this.handleAdminUsernameChange}
                               placeHolder={"Admin e-post"}/>
                    <span style={{fontSize: fontSize, marginLeft: textMargin}}>Admin lösenord:</span>
                    <TextInput value={nasmo.adminPassword} onChange={this.handleAdminPasswordChange}
                               placeHolder={"Admin lösenord"} password={true}/>
                </div>
        );
    }

    private handleSystemIdChange = (currentValue: string) => {
        const {nasmo} = this.props;
        this.props.onChange( {systemId: currentValue, adminPassword: nasmo.adminPassword, adminUsername: nasmo.adminUsername, bookingUsername: nasmo.bookingUsername});
    };

    private handleBookingsUsernameChange = (currentValue: string) => {
        const {nasmo} = this.props;
        this.props.onChange( {systemId: nasmo.systemId, adminPassword: nasmo.adminPassword, adminUsername: nasmo.adminUsername, bookingUsername: currentValue});
    };

    private handleAdminUsernameChange = (currentValue: string) => {
        const {nasmo} = this.props;
        this.props.onChange( {systemId: nasmo.systemId, adminPassword: nasmo.adminPassword, adminUsername: currentValue, bookingUsername: nasmo.bookingUsername});
    };

    private handleAdminPasswordChange = (currentValue: string) => {
        const {nasmo} = this.props;
        this.props.onChange( {systemId: nasmo.systemId, adminPassword: currentValue, adminUsername: nasmo.adminUsername, bookingUsername: nasmo.bookingUsername});
    };
}