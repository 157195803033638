import * as React from "react"
import {RouteComponentProps} from "react-router"
import BasePage, {BasePageProps} from "../../BasePage"
import {mobile} from "../../../App"
import {Redirect} from "react-router-dom"

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }>

interface State {
    redirectTo?: string
}

export default class SettingsPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {};
    }


    render() {
        const {redirectTo} = this.state;
        const marginTop = mobile ? "0" : "3rem";
        const buttonWidth = mobile ? "80vw" : "13rem";
        const fontSize = mobile ? "3rem" : "1rem";
        const {groupplayId} = this.props.match.params;
        if (redirectTo) {
            return <Redirect to={redirectTo}/>
        }
        return (
            <BasePage {...this.props} adminPage={true} loading={false} fatalError={false}>
                <div style={{
                    margin: marginTop + " auto 0 auto",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                    <span style={{marginBottom: "1rem", fontSize: fontSize}}>Inställningar:</span>
                    <button style={{marginLeft: 0, marginRight: 0, width: buttonWidth}}
                            onClick={() => this.setState({redirectTo: "/gruppspel/" + groupplayId + "/admin/bookingSystemSettings"})}>Bokningssystem
                    </button>
                    <button style={{marginLeft: 0, marginRight: 0, width: buttonWidth}}
                            onClick={() => this.setState({redirectTo: "/gruppspel/" + groupplayId + "/admin/bookingSettings"})}>Bokningsregler
                    </button>
                    <button style={{marginLeft: 0, marginRight: 0, width: buttonWidth}}
                            onClick={() => this.setState({redirectTo: "/gruppspel/" + groupplayId + "/admin/rules"})}>Poängsystem
                    </button>
                    <button style={{marginLeft: 0, marginRight: 0, width: buttonWidth}}
                            onClick={() => this.setState({redirectTo: "/gruppspel/" + groupplayId + "/admin/rulesText"})}>Regeltext
                    </button>
                    <button style={{marginLeft: 0, marginRight: 0, width: buttonWidth}}
                            onClick={() => this.setState({redirectTo: "/gruppspel/" + groupplayId + "/admin/groupplaySettings"})}>Övrigt
                    </button>
                </div>
            </BasePage>
        );
    }
}