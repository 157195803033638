import * as React from "react"
import {RouteComponentProps} from "react-router";
import BasePage, {BasePageProps} from "../BasePage";
import {getSchedule} from "../../api/api";
import {MatchDto, ScheduleDto} from "../../api/dtos";
import Schedule from "./Schedule";
import FullPageMessage from "../../components/FullPageMessage";
import RoundOpenUntilWidget from "./RoundOpenUntilWidget";
import ScheduleFilter from "./ScheduleFilter";
import {FilterGroups, FilterTimes} from "./FilterType";

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }>

interface State {
    fatalError: boolean
    loading: boolean
    schedule?: ScheduleDto
    myGroupNr?: number
    matches: MatchDto[]
    allMatches: MatchDto[]
    now: Date
}

class SchedulePage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            fatalError: false,
            loading: true,
            matches: [],
            allMatches: [],
            now: new Date()
        };
        this.loadSchedule = this.loadSchedule.bind(this);
        this.roundOpenUntilChanged = this.roundOpenUntilChanged.bind(this);
        this.filterUpdated = this.filterUpdated.bind(this);
    }

    async componentDidUpdate(prevProps: Props) {
        if (prevProps.session !== this.props.session) {
            console.log("reloading schedule");
            this.loadSchedule();
        }
    }

    async componentDidMount() {
        this.loadSchedule();
    }

    private async loadSchedule() {
        this.setState({loading: true});
        const {groupplayId} = this.props.match.params;
        const {now} = this.state;
        const session = this.props.session;
        const sessionId = session ? session.sessionId : undefined;
        try {
            const schedule = await getSchedule(groupplayId, sessionId);
            const matches: MatchDto[] = schedule.myGroupNr ?
                SchedulePage.filterMatches(schedule.matches, FilterTimes.ALL_TIMES, FilterGroups.MY_GROUP, now, schedule.myGroupNr)
                :
                SchedulePage.filterMatches(schedule.matches, FilterTimes.ALL_TIMES, FilterGroups.ALL_GROUPS, now, schedule.myGroupNr);
            this.setState({
                schedule: schedule,
                now: new Date(schedule.now),
                allMatches: schedule.matches,
                matches: matches,
                myGroupNr: schedule.myGroupNr
            });
        } catch (error:any) {
            switch (error.type) {
                case "NOT_FOUND":
                    //just ignore..
                    break;
                default:
                    console.log('ERROR' + error);
                    this.setState({fatalError: true});
                    break;
            }
        }
        this.setState({loading: false});
    }


    render() {
        const {groupplayId} = this.props.match.params;
        const {session} = this.props;
        const {matches, schedule,myGroupNr} = this.state;
        const groupFilter = myGroupNr ? FilterGroups.MY_GROUP : FilterGroups.ALL_GROUPS;

        if (!schedule) {
            return <BasePage {...this.props} loading={this.state.loading} fatalError={this.state.fatalError}
                             hideScheduleButton={true}>
                <FullPageMessage text={"Det finns inget spelschema än.."}/>
            </BasePage>
        } else {
            return (
                <BasePage {...this.props} loading={this.state.loading} fatalError={this.state.fatalError}
                          hideScheduleButton={true}>
                    <ScheduleFilter filterUpdated={this.filterUpdated} myGroupNr={myGroupNr}
                                    timeFilter={FilterTimes.ALL_TIMES} groupFilter={groupFilter}/>

                    <Schedule matches={matches} session={this.props.session} groupplayId={groupplayId}/>
                    <RoundOpenUntilWidget onChange={this.roundOpenUntilChanged} date={schedule.openUntil.date}
                                          hour={schedule.openUntil.hour} minute={schedule.openUntil.minute}
                                          session={session}
                                          roundNr={schedule.roundNumber} groupplayId={groupplayId}/>
                </BasePage>
            );
        }
    }

    roundOpenUntilChanged() {
        this.loadSchedule();
    }

    filterUpdated(timeFilter: FilterTimes, groupFilter: FilterGroups) {
        const {allMatches, now, myGroupNr} = this.state;
        const filteredMatched: MatchDto[] = SchedulePage.filterMatches(allMatches, timeFilter, groupFilter, now, myGroupNr);
        this.setState({matches: filteredMatched});
    }

    static filterMatches(allMatches: MatchDto[], timeFilter: FilterTimes, groupFilter: FilterGroups, now: Date, myGroupNr?: Number): MatchDto[] {
        return allMatches
            .filter(match => {
                if (timeFilter === FilterTimes.FUTURE_TIMES) {
                    if (match.timePlace) {
                        const matchDate = new Date(match.timePlace.time.date);
                        if (matchDate < now) {
                            return false;
                        }
                    }
                }
                return true;
            })
            .filter(match => {
                if (groupFilter === FilterGroups.MY_GROUP && myGroupNr) {
                    if(match.groupNr !== myGroupNr) {
                        return false;
                    }
                }
                return true;
            });
    }
}

export default SchedulePage;