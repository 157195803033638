import * as React from "react"
import {Link, Redirect} from "react-router-dom"
import {GroupplayDto, SessionDto} from "../api/dtos"
import {getGroupplay, logout} from "../api/api"
import {mobile} from "../App"

interface Props {
    session?: SessionDto
    onSessionChange: (rememberMe: boolean, session?: SessionDto) => void
    fatalError: () => void
    groupplayId?: string
    noLoginButton?: boolean
    hideScheduleButton?: boolean
    hideStandingsButton?: boolean
}

interface State {
    showPersonalDropdown: boolean
    showHamburgerDropdown: boolean
    showAdminDropdown: boolean
    redirectTo?: string
    groupplayName?: string
    showHistoryButton: boolean
}

export default class PageHeader extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            showPersonalDropdown: false,
            showAdminDropdown: false,
            showHamburgerDropdown: false,
            showHistoryButton: false
        };
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.nameClick = this.nameClick.bind(this);
        this.adminClick = this.adminClick.bind(this);
        this.hamburgerClick = this.hamburgerClick.bind(this);
    }

    async componentDidMount() {
        const groupplayId = this.props.groupplayId;

        if (groupplayId && !this.state.groupplayName) {
            try {
                const groupplay: GroupplayDto = await getGroupplay(groupplayId);
                this.setState({
                    groupplayName: groupplay.name,
                    showHistoryButton: (groupplay.nrOfRounds > 1)
                });
            } catch (error:any) {
                this.props.fatalError();
            }
        }
    }

    componentDidUpdate(prevProps : Props, prevState: State) {
        if(this.state.redirectTo !== undefined && this.state.redirectTo === prevState.redirectTo) {
            // to avoid infinite loop if you pre the same page twice in the menu
            this.setState({redirectTo: undefined});
        }
    }

    render() {
        const {redirectTo, groupplayName} = this.state;
        if (redirectTo) {
            return <Redirect to={redirectTo}/>
        }
        return (
            <div className="full-width" style={{position: "fixed", backgroundColor: "var(--pageBg)", zIndex: 10}}>
                {mobile && groupplayName && <div style={{textAlign: "center", fontSize: "2.25rem"}}>{groupplayName}</div>}
                {!mobile && groupplayName &&
                <div style={{
                    position: "absolute",
                    left: "50%",
                    display: "inline-block",
                    fontSize: "1.2rem",
                    width: "21rem",
                    textAlign: "center",
                    marginLeft: "-10.5rem",
                    marginTop: "0.5rem"
                }}>
                    {this.state.groupplayName}
                </div>}
                {this.props.session && this.profileButton()}

                {!this.props.noLoginButton && !this.props.session &&
                <Link to="/login" style={{float: "right"}}>
                    <button>Login</button>
                </Link>}

                {this.props.groupplayId && !this.props.hideScheduleButton && this.scheduleButton()}
                {this.props.groupplayId && !this.props.hideStandingsButton && this.standingsButton()}
                {this.props.groupplayId  && this.hamburgerButton()}

                {this.props.groupplayId && this.props.session &&
                this.props.session.adminGroupplayIds.includes(this.props.groupplayId) &&
                this.adminButton()}
            </div>
        );
    }

    private hamburgerButton() {
        let elements : React.JSX.Element[] = [];
        const fontSize = mobile ? "4rem" : "1rem";
        const width = mobile ? "24rem" : "6.5rem";
        const left = mobile ? "-0.3rem" : "-0.3rem";
        const top = mobile ? "7rem" : "2.4rem";
        const paddingTop = mobile ? "2rem" : "0.25rem";
        const height = mobile ? "2rem" : "1rem";
        elements.push(
            <button key="pbutton" style={{position: "relative", float: "left", textAlign: "left"}}
                    onClick={this.hamburgerClick}>
                <svg key="hsvg" style={{marginTop: mobile ? "0" : "0.2rem"}} width="1.2em" height="1.2em"
                     viewBox="0 0 24.75 24.75">
                    <path
                        d="M0,3.875c0-1.104,0.896-2,2-2h20.75c1.104,0,2,0.896,2,2s-0.896,2-2,2H2C0.896,5.875,0,4.979,0,3.875z M22.75,10.375H2 c-1.104,0-2,0.896-2,2c0,1.104,0.896,2,2,2h20.75c1.104,0,2-0.896,2-2C24.75,11.271,23.855,10.375,22.75,10.375z M22.75,18.875H2 c-1.104,0-2,0.896-2,2s0.896,2,2,2h20.75c1.104,0,2-0.896,2-2S23.855,18.875,22.75,18.875z"/>
                </svg>
                {this.state.showHamburgerDropdown &&
                <div style={{
                    position: "absolute", width: "100%", height: height,
                    backgroundColor: "var(--buttonBg)", zIndex: 0, right: 0, top: mobile ? "5.5rem" : "1.5rem"
                }}/>}
                {this.state.showHamburgerDropdown && this.props.groupplayId &&
                <ul key="hdropdown" style={{
                    position: "absolute", margin: "0 0 0 0.3rem", padding: 0, left: left, top: top,
                    listStyle: "none", width: width, backgroundColor: "var(--buttonBg)",
                    color: "black", fontSize: fontSize
                }}>
                    <li key={1} style={{padding: paddingTop + " 0.25rem 0.25rem 0.25rem", cursor: "pointer"}}
                        onClick={() => this.setState({redirectTo: "/gruppspel/" + this.props.groupplayId + "/rules"})}>Regler
                    </li>
                    {this.state.showHistoryButton &&
                    <li key={2} style={{padding: paddingTop + " 0.25rem 0.25rem 0.25rem", cursor: "pointer"}}
                        onClick={() => this.setState({redirectTo: "/gruppspel/" + this.props.groupplayId + "/history"})}>Historik
                    </li>}
                    {this.props.session && (this.props.session.groupplayIds.includes(this.props.groupplayId) ||
                    this.props.session.adminGroupplayIds.includes(this.props.groupplayId)) &&
                    <li key={4} style={{padding: paddingTop + " 0.25rem 0.25rem 0.25rem", cursor: "pointer"}}
                        onClick={() => this.setState({redirectTo: "/gruppspel/" + this.props.groupplayId + "/contacts"})}>Telefon/Mail
                    </li>}
                </ul>}
            </button>);
        return elements;
    }

    private profileButton() {
        let elements : React.JSX.Element[] = [];
        if (this.props.session) {
            const {session} = this.props;
            let buttonContent = [
                <svg key="psvg" style={{verticalAlign: "middle"}}
                     xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
                    <path
                        d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"/>
                </svg>];
            if (!mobile) {
                buttonContent.push(<span key="ptext"
                                         style={{marginLeft: "0.5rem"}}>{session.firstName}</span>);
            }
            const height = mobile ? "3rem" : "1rem";
            elements.push(
                <button key="pbutton" style={{position: "relative", float: "right"}} onClick={this.nameClick}>
                    {buttonContent}
                    {this.state.showPersonalDropdown &&
                    <div style={{
                        position: "absolute", width: "100%", height: height,
                        backgroundColor: "var(--buttonBg)", zIndex: 0, right: 0
                    }}/>}
                </button>);
            const fontSize = mobile ? "4rem" : "1rem";
            const width = mobile ? "32rem" : "8rem";
            const right = mobile ? "1rem" : "0.3rem";
            const top = mobile ? "9rem" : "2.8rem";
            const paddingTop = mobile ? "2rem" : "0.25rem";
            if (this.state.showPersonalDropdown) {
                elements.push(
                    <ul key="pdropdown" style={{
                        position: "absolute", margin: "0 0 0 0.3rem", padding: 0, right: right, top: top,
                        listStyle: "none", width: width, backgroundColor: "var(--buttonBg)",
                        color: "black", fontSize: fontSize
                    }}>
                        <li key={1} style={{padding: paddingTop + " 0.25rem 0.25rem 0.25rem", cursor: "pointer"}}
                            onClick={() => this.setState({redirectTo: "/editTimeRatings"})}>Tidsönskemål
                        </li>
                        <li key={2} style={{padding: paddingTop + " 0.25rem 0.25rem 0.25rem", cursor: "pointer"}}
                            onClick={() => this.setState({redirectTo: "/editCantPlayDates"})}>Bortrest
                        </li>
                        <li key={3} style={{padding: paddingTop + " 0.25rem 0.25rem 0.25rem", cursor: "pointer"}}
                            onClick={() => this.setState({redirectTo: "/changePassword"})}>Lösenord
                        </li>
                        {this.props.groupplayId && session.groupplayIds.includes(this.props.groupplayId) &&
                        <li key={4} style={{padding: paddingTop + " 0.25rem 0.25rem 0.25rem", cursor: "pointer"}}
                            onClick={() => this.setState({redirectTo: "/gruppspel/" + this.props.groupplayId + "/wannaJoinOrQuit"})}>Pausa/Sluta
                        </li>}

                        <li key={5} style={{padding: paddingTop + " 0.25rem 0.25rem 0.25rem", cursor: "pointer"}}
                            onClick={() => this.setState({redirectTo: "/editPersonalPrefs"})}>Personuppgifter
                        </li>
                        <li key={6}
                            style={{padding: paddingTop + " 0.25rem " + paddingTop + " 0.25rem", cursor: "pointer"}}
                            onClick={this.handleLogoutClick}>Logga ut
                        </li>
                    </ul>
                );
            }
        }
        return elements;
    }

    private adminButton() {
        let elements : React.JSX.Element[] = [];
        if (this.props.session) {
            let buttonContent = [
                <svg key="asvg" style={{verticalAlign: "middle"}}
                     xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em"
                     viewBox="0 0 24 24">
                    <path
                        d="M17 10.645v-2.29c-1.17-.417-1.907-.533-2.28-1.431-.373-.9.07-1.512.6-2.625l-1.618-1.619c-1.105.525-1.723.974-2.626.6-.9-.373-1.017-1.116-1.431-2.28h-2.29c-.412 1.158-.53 1.907-1.431 2.28h-.001c-.9.374-1.51-.07-2.625-.6l-1.617 1.619c.527 1.11.973 1.724.6 2.625-.375.901-1.123 1.019-2.281 1.431v2.289c1.155.412 1.907.531 2.28 1.431.376.908-.081 1.534-.6 2.625l1.618 1.619c1.107-.525 1.724-.974 2.625-.6h.001c.9.373 1.018 1.118 1.431 2.28h2.289c.412-1.158.53-1.905 1.437-2.282h.001c.894-.372 1.501.071 2.619.602l1.618-1.619c-.525-1.107-.974-1.723-.601-2.625.374-.899 1.126-1.019 2.282-1.43zm-8.5 1.689c-1.564 0-2.833-1.269-2.833-2.834s1.269-2.834 2.833-2.834 2.833 1.269 2.833 2.834-1.269 2.834-2.833 2.834zm15.5 4.205v-1.077c-.55-.196-.897-.251-1.073-.673-.176-.424.033-.711.282-1.236l-.762-.762c-.52.248-.811.458-1.235.283-.424-.175-.479-.525-.674-1.073h-1.076c-.194.545-.25.897-.674 1.073-.424.176-.711-.033-1.235-.283l-.762.762c.248.523.458.812.282 1.236-.176.424-.528.479-1.073.673v1.077c.544.193.897.25 1.073.673.177.427-.038.722-.282 1.236l.762.762c.521-.248.812-.458 1.235-.283.424.175.479.526.674 1.073h1.076c.194-.545.25-.897.676-1.074h.001c.421-.175.706.034 1.232.284l.762-.762c-.247-.521-.458-.812-.282-1.235s.529-.481 1.073-.674zm-4 .794c-.736 0-1.333-.597-1.333-1.333s.597-1.333 1.333-1.333 1.333.597 1.333 1.333-.597 1.333-1.333 1.333zm-4 3.071v-.808c-.412-.147-.673-.188-.805-.505s.024-.533.212-.927l-.572-.571c-.389.186-.607.344-.926.212s-.359-.394-.506-.805h-.807c-.146.409-.188.673-.506.805-.317.132-.533-.024-.926-.212l-.572.571c.187.393.344.609.212.927-.132.318-.396.359-.805.505v.808c.408.145.673.188.805.505.133.32-.028.542-.212.927l.572.571c.39-.186.608-.344.926-.212.318.132.359.395.506.805h.807c.146-.409.188-.673.507-.805h.001c.315-.131.529.025.924.213l.572-.571c-.186-.391-.344-.609-.212-.927s.397-.361.805-.506zm-3 .596c-.552 0-1-.447-1-1s.448-1 1-1 1 .447 1 1-.448 1-1 1z"/>
                </svg>];
            if (!mobile) {
                buttonContent.push(<span key="ktext" style={{marginLeft: "0.5rem"}}>Admin</span>);
            }
            const height = mobile ? "3rem" : "1rem";
            elements.push(
                <button key="abutton" style={{position: "relative", float: "right"}} onClick={this.adminClick}>
                    {buttonContent}
                    {this.state.showAdminDropdown &&
                    <div style={{
                        position: "absolute", width: "100%", height: height,
                        backgroundColor: "var(--buttonBg)", zIndex: 0, right: 0
                    }}/>}
                </button>);
            const fontSize = mobile ? "4rem" : "1rem";
            const width = mobile ? "32rem" : "9rem";
            const right = mobile ? "7rem" : "4rem";
            const top = mobile ? "9rem" : "2.8rem";
            const paddingTop = mobile ? "2rem" : "0.25rem";
            if (this.state.showAdminDropdown) {
                elements.push(
                    <ul key="adropdown" style={{
                        position: "absolute", margin: "0 0 0 0.3rem", padding: 0, right: right, top: top,
                        listStyle: "none", width: width, backgroundColor: "var(--buttonBg)",
                        color: "black", fontSize: fontSize
                    }}>
                        <li key={1} style={{padding: paddingTop + " 0.25rem 0.25rem 0.25rem", cursor: "pointer"}}
                            onClick={() => this.setState({redirectTo: "/gruppspel/" + this.props.groupplayId + "/admin/players"})}>Grupper
                        </li>
                        <li key={2} style={{padding: paddingTop + " 0.25rem 0.25rem 0.25rem", cursor: "pointer"}}
                            onClick={() => this.setState({redirectTo: "/gruppspel/" + this.props.groupplayId + "/admin/bookings"})}>Tider
                        </li>
                        <li key={3} style={{padding: paddingTop + " 0.25rem 0.25rem 0.25rem", cursor: "pointer"}}
                            onClick={() => this.setState({redirectTo: "/gruppspel/" + this.props.groupplayId + "/admin/newSchedule"})}>Nytt spelschema
                        </li>
                        <li key={4} style={{padding: paddingTop + " 0.25rem 0.25rem 0.25rem", cursor: "pointer"}}
                            onClick={() => this.setState({redirectTo: "/gruppspel/" + this.props.groupplayId + "/admin/settings"})}>Inställningar
                        </li>
                        <li key={5} style={{padding: paddingTop + " 0.25rem " + paddingTop + " 0.25rem", cursor: "pointer"}}
                            onClick={() => this.setState({redirectTo: "/gruppspel/" + this.props.groupplayId + "/contacts"})}>Telefon/Mail
                        </li>
                    </ul>
                );
            }
        }
        return elements;
    }

    private standingsButton() {
        let buttonContent = [<svg key="svg" style={{verticalAlign: "middle"}} xmlns="http://www.w3.org/2000/svg"
                                  width="1.2em" height="1.2em" viewBox="0 0 476.473 476.474">
            <path d="M463.468,227.853c-40.177-1.468-80.359-1.686-120.557-1.717c-0.492-43.122,0.635-70.609,0.122-113.731
			c3.448-7.695-0.284-18.88-11.294-18.88c-53.537,0-122.828,3.951-176.335,1.915c-2.323-0.635-4.857-0.666-7.289-0.094
			c-8.311,0.541-11.878,8.092-10.672,14.944c-1.828,25.624-1.823,51.295-2.214,76.967c-36.467,0.614-83.337,0.729-119.81,0.733
			c-5.512,0-9.179,2.829-11.065,6.604c-2.194,2.084-3.661,5.06-3.671,8.996c-0.099,51.455-1.473,108.105-0.025,159.555
			c-1.686,7.374,2.343,16.301,12.157,16.372c149.75,1.056,300.085,5.981,449.79,1.731c7.881-0.224,12.009-6.114,12.472-12.232
			c0.857-1.682,1.396-3.646,1.396-5.967V243.452c0-1.417-0.239-2.666-0.584-3.845C475.213,233.702,471.095,228.134,463.468,227.853z
			 M450.468,355.508c-141.143,3.544-282.803-0.65-423.988-1.858c-0.972-44.823-0.094-94.852,0.13-139.686
			c36.427-0.015,83.246-0.109,119.665-0.843c1.368-0.025,2.579-0.282,3.722-0.619c5.68-0.874,10.832-4.997,11.009-12.38
			c0.625-26.157,0.452-52.326,1.795-78.45c46.169,1.295,108.059-1.482,154.264-2.034c0.34,44.453-0.898,73.278-0.061,117.746
			c0.025,1.32,0.259,2.498,0.573,3.615c0.736,5.81,4.854,11.116,12.426,11.116c40.162,0,80.313,0.146,120.465,1.36V355.508z"/>
            <path d="M243.48,134.334c-0.825,0.165-1.633,0.457-2.344,0.987c-7.81,5.819-15.861,11.314-23.244,17.685
			c-7.627,6.583,3.453,17.564,11.029,11.029c3.187-2.75,6.52-5.299,9.899-7.795c-0.229,18.382-0.63,36.762-0.681,55.145
			c-0.025,10.062,15.577,10.057,15.603,0c0.061-23.404,0.802-46.794,0.868-70.198C254.625,134.672,248.101,132.404,243.48,134.334z"
            />
            <path d="M112.476,285.919c-4.804,0-9.6,0.051-14.394,0.157c8.163-8.282,14.254-18.448,15.138-29.854
			c1.991-25.755-32.042-21.235-47.017-16.818c-9.625,2.834-5.527,17.891,4.148,15.041c5.33-1.574,29.597-9.343,27.025,3.413
			c-2.709,13.426-16.547,23.998-27.835,29.995c-2.471,1.311-3.61,3.296-3.829,5.383c-1.678,4.783,0.584,10.761,6.896,10.019
			c13.253-1.548,26.535-1.731,39.867-1.731C122.538,301.519,122.538,285.919,112.476,285.919z"/>
            <path d="M373.319,288.661c5.397-1.138,10.785-2.097,16.274-2.574c2.691-0.239,5.81-0.29,8.293-0.076
			c-1.849,2.473-4.276,4.672-6.617,6.692c-1.533,0.178-3.017,0.873-4.296,2.234c-2.706,2.904-3.188,8.663,0,11.477
			c4.611,4.082,9.217,8.43,12.294,13.989c0.264,0.493,0.503,0.99,0.736,1.498c0.041,0.122,0.066,0.214,0.173,0.519
			c0.046,0.162,0.081,0.335,0.122,0.502c-0.082,0.021-0.117,0.031-0.152,0.041c-0.285,0.021-0.569,0.051-0.858,0.056
			c-2.311,0.112-3.428-0.203-5.946-0.889c-6.586-1.787-12.578-5.57-18.215-9.526c-8.19-5.753-15.768,8.303-7.638,14.016
			c11.004,7.733,31.316,19.565,43.646,8.201c10.456-9.644,1.818-23.704-6.683-33.139c8.079-8.161,16.188-20.125,4.769-28.854
			c-4.397-3.356-11.167-3.301-16.296-3.164c-7.916,0.214-15.873,1.722-23.627,3.357
			C359.725,275.032,363.788,290.672,373.319,288.661z"/>
        </svg>];
        if (!mobile) {
            buttonContent.push(<span key="text" style={{marginLeft: "0.5rem"}}>Tabell</span>);
        }
        return <Link to={"/gruppspel/" + this.props.groupplayId + "/table"}>
            <button style={{position: "relative", float: "right"}}> {buttonContent} </button>
        </Link>
    }

    private scheduleButton() {
        let buttonContent = [<svg key="svg" style={{verticalAlign: "middle"}} xmlns="http://www.w3.org/2000/svg"
                                  width="1.2em" height="1.2em" viewBox="0 0 29.237 29.237">
            <path d="M7.685,24.819H8.28v-2.131h3.688v2.131h0.596v-2.131h3.862v2.131h0.597v-2.131h4.109v2.131h0.595
			v-2.131h3.417v-0.594h-3.417v-3.861h3.417v-0.596h-3.417v-3.519h3.417v-0.594h-3.417v-2.377h-0.595v2.377h-4.109v-2.377h-0.597
			v2.377h-3.862v-2.377h-0.596v2.377H8.279v-2.377H7.685v2.377H3.747v0.594h3.938v3.519H3.747v0.596h3.938v3.861H3.747v0.594h3.938
			V24.819z M12.563,22.094v-3.861h3.862v3.861H12.563z M21.132,22.094h-4.109v-3.861h4.109V22.094z M21.132,14.118v3.519h-4.109
			v-3.519C17.023,14.119,21.132,14.119,21.132,14.118z M16.426,14.118v3.519h-3.862v-3.519
			C12.564,14.119,16.426,14.119,16.426,14.118z M8.279,14.118h3.688v3.519H8.279V14.118z M8.279,18.233h3.688v3.861H8.279V18.233z"
            />
            <path d="M29.207,2.504l-4.129,0.004L24.475,2.51v2.448c0,0.653-0.534,1.187-1.188,1.187h-1.388
			c-0.656,0-1.188-0.533-1.188-1.187V2.514l-1.583,0.002v2.442c0,0.653-0.535,1.187-1.191,1.187h-1.388
			c-0.655,0-1.188-0.533-1.188-1.187V2.517l-1.682,0.004v2.438c0,0.653-0.534,1.187-1.189,1.187h-1.389
			c-0.653,0-1.188-0.533-1.188-1.187V2.525H8.181v2.434c0,0.653-0.533,1.187-1.188,1.187H5.605c-0.656,0-1.189-0.533-1.189-1.187
			V2.53L0,2.534v26.153h2.09h25.06l2.087-0.006L29.207,2.504z M27.15,26.606H2.09V9.897h25.06V26.606z"/>
            <path d="M5.605,5.303h1.388c0.163,0,0.296-0.133,0.296-0.297v-4.16c0-0.165-0.133-0.297-0.296-0.297H5.605
			c-0.165,0-0.298,0.132-0.298,0.297v4.16C5.307,5.17,5.44,5.303,5.605,5.303z"/>
            <path d="M11.101,5.303h1.389c0.164,0,0.297-0.133,0.297-0.297v-4.16c-0.001-0.165-0.134-0.297-0.298-0.297
			H11.1c-0.163,0-0.296,0.132-0.296,0.297v4.16C10.805,5.17,10.938,5.303,11.101,5.303z"/>
            <path d="M16.549,5.303h1.388c0.166,0,0.299-0.133,0.299-0.297v-4.16c-0.001-0.165-0.133-0.297-0.299-0.297
			h-1.388c-0.164,0-0.297,0.132-0.297,0.297v4.16C16.252,5.17,16.385,5.303,16.549,5.303z"/>
            <path d="M21.899,5.303h1.388c0.164,0,0.296-0.133,0.296-0.297v-4.16c0-0.165-0.132-0.297-0.296-0.297
			h-1.388c-0.164,0-0.297,0.132-0.297,0.297v4.16C21.603,5.17,21.735,5.303,21.899,5.303z"/>
        </svg>];
        if (!mobile) {
            buttonContent.push(<span key="text" style={{marginLeft: "0.5rem"}}>Schema</span>);
        }
        return <Link to={"/gruppspel/" + this.props.groupplayId + "/schema"}>
            <button style={{position: "relative", float: "right"}}> {buttonContent} </button>
        </Link>
    }

    async nameClick() {
        if (this.state.showPersonalDropdown) {
            this.setState({showPersonalDropdown: false});
        } else {
            this.setState({showPersonalDropdown: true, showAdminDropdown: false, showHamburgerDropdown: false});
        }
    }

    async hamburgerClick() {
        if (this.state.showHamburgerDropdown) {
            this.setState({showHamburgerDropdown: false});
        } else {
            this.setState({showHamburgerDropdown: true, showAdminDropdown: false, showPersonalDropdown: false});
        }
    }

    async adminClick() {
        if (this.state.showAdminDropdown) {
            this.setState({showAdminDropdown: false});
        } else {
            this.setState({showAdminDropdown: true, showPersonalDropdown: false, showHamburgerDropdown: false});
        }
    }

    async handleLogoutClick() {
        if (this.props.session) {
            try {
                await logout(this.props.session.sessionId);
                this.props.onSessionChange(false, undefined);
            } catch (error:any) {
                console.log('ERROR while logging out')
                this.props.fatalError();
            }
        }
    }
}