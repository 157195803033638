import * as React from "react"
import {RouteComponentProps} from "react-router"
import BasePage, {BasePageProps} from "../BasePage"
import {getRounds} from "../../api/api"
import {RoundOverviewDto, RoundStandingsDto} from "../../api/dtos"
import Standings from "../standings/Standings"
import Table from "../../components/Table"
import TableRow from "../../components/TableRow"
import TableText from "../../components/TableText"
import TableButton, {ButtonType} from "../../components/TableButton"
import {mobile} from "../../App"

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }>

interface State {
    loading: boolean
    fatalError: boolean
    rounds : RoundOverviewDto[]
    standings?: RoundStandingsDto
}

export default class HistoryPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            fatalError: false,
            rounds: []
        }
    }

    async componentDidMount() {
        this.setState({loading: true});
        const {groupplayId} = this.props.match.params;
        try {
            const rounds : RoundOverviewDto[] = await getRounds(groupplayId);
            this.setState({rounds: rounds});
        } catch (error:any) {
            console.log('ERROR' + error);
            switch (error.type) {
                case "NOT_FOUND":
                    //just ignore..
                    break;
                default:
                    this.setState({fatalError: true});
                    break;
            }
        }
        this.setState({loading: false})
    }

    render() {
        const {groupplayId} = this.props.match.params;
        const {rounds} = this.state;

        return (
            <BasePage {...this.props} loading={this.state.loading} fatalError={this.state.fatalError}>
                {this.renderRounds(groupplayId, rounds)}
                {this.state.standings &&
                <Standings standings={this.state.standings} groupplayId={groupplayId}/>}
            </BasePage>
        );
    }

    private renderRounds = (groupplayId: string, rounds : RoundOverviewDto[]) => {
        return <Table>
            {rounds
                .filter((r, index) => index !== 0) // skip first round, its the latest round
                .map((r, index) => this.renderRound(groupplayId, r, (index + 1) === rounds.length))}
        </Table>
    };

    private renderRound = (groupplayId: string, round : RoundOverviewDto, lastRow: boolean) => {
        return <TableRow key={round.roundNr} lastRow={lastRow} width="38rem">
            <TableText left="0" mobLeft="0.5rem">{mobile?"":"Omgång "}{round.roundNr}</TableText>
            <TableText left="6rem" mobLeft="5rem" mobFirstRow>{round.firstMatchStart.date} till {round.openUntil.date}</TableText>
            <TableText right="14rem" mobLeft="5rem" mobSecondRow>{round.nrOfGroups} grupper</TableText>
            <TableText right="8rem" mobLeft="15rem" mobSecondRow>{round.nrOfPlayers} spelare</TableText>
            <TableButton type={ButtonType.STANDINGS} right="0" mobRight="0"
                         link={"/gruppspel/" + groupplayId + "/history/table/" + round.roundNr}/>
            <TableButton type={ButtonType.SCHEDULE} right="3rem" mobRight="7rem"
                         link={"/gruppspel/" + groupplayId + "/history/schedule/" + round.roundNr}/>
        </TableRow>



    }

}