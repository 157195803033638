import * as React from "react"
import Checkbox from "../../../components/Checkbox"
import {BookingSystemSettingsDto, CourtNameAliasDto, MatchiConfigDto, NasmoConfigDto, TestBookingSystemDto} from "../../../api/dtos"
import MatchiConfig from "./MatchiConfig"
import NasmoConfig from "./NasmoConfig"
import BasePage, {BasePageProps} from "../../BasePage"
import {getBookingsSystemSettings, getCourtNameAliases, saveBookingsSystemSettings, testBookingSystem} from "../../../api/api"
import {RouteComponentProps} from "react-router"
import FullPageSpinner from "../../../components/FullPageSpinner"
import FullPageMessage from "../../../components/FullPageMessage"
import {ErrorMessage} from "../../../components/ErrorMessage"
import Panel from "../../../components/Panel"
import Table from "../../../components/Table"
import TableRow from "../../../components/TableRow"
import TableText from "../../../components/TableText"

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }>

enum BookingSystem {
    NASMO = "NASMO",
    MATCHI = "MATCHI"
}

interface State {
    bookingSystem: BookingSystem
    config?: BookingSystemSettingsDto
    matchi: MatchiConfigDto
    nasmo: NasmoConfigDto
    courtNameAliases: CourtNameAliasDto[]
    loading: boolean
    fatalError: boolean
    testingCommunication: boolean,
    communicationSuccessful: boolean,
    futureBookingsFound: number,
    errorMessage?: string
}

export default class BookingsSystemSettingsPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            loading: true,
            fatalError: false,
            bookingSystem: BookingSystem.MATCHI,
            matchi: {bookingUsername: "", adminUsername: "", adminPassword: ""},
            nasmo: {systemId: "", bookingUsername: "", adminUsername: "", adminPassword: ""},
            testingCommunication: false,
            communicationSuccessful: false,
            futureBookingsFound: 0,
            courtNameAliases: []
        }
    }

    async componentDidMount() {
        const {groupplayId} = this.props.match.params
        if (this.props.session) {
            try {
                const config: BookingSystemSettingsDto = await getBookingsSystemSettings(groupplayId, this.props.session.sessionId)
                const aliases: CourtNameAliasDto[] = await getCourtNameAliases(groupplayId, this.props.session.sessionId)
                this.setState({
                    config: config,
                    nasmo: config.nasmo ? config.nasmo : {systemId: "", bookingUsername: "", adminUsername: "", adminPassword: ""},
                    matchi: config.matchi ? config.matchi : {bookingUsername: "", adminUsername: "", adminPassword: ""},
                    bookingSystem: config.matchi ? BookingSystem.MATCHI : BookingSystem.NASMO,
                    loading: false,
                    courtNameAliases: aliases
                })
            } catch (error: any) {
                console.log('ERROR:' + error.type)
                switch (error.type) {
                    case "NOT_FOUND":
                        this.setState({loading: false})
                        break
                    default:
                        this.setState({fatalError: true})
                        break
                }
            }
        }
    }

    render() {
        const {
            bookingSystem, matchi, nasmo, loading, fatalError, testingCommunication, communicationSuccessful,
            futureBookingsFound, errorMessage, courtNameAliases
        } = this.state
        if (testingCommunication && !fatalError) {
            return (<FullPageSpinner text="Testar kommunikationen med bokningssystemet.."/>)
        }
        if (communicationSuccessful) {
            return (<BasePage {...this.props} adminPage={true} loading={loading} fatalError={fatalError}>
                <FullPageMessage text={"Kommunikationen med bokningssystemet fungerar! Hittade " +
                    futureBookingsFound + " bokningar de kommande 30 dagarna."}/>
            </BasePage>)
        }
        return (
            <BasePage {...this.props} adminPage={true} loading={loading} fatalError={fatalError}>
                <div style={{margin: "auto", display: "flex", flexDirection: "column", alignItems: "center"}}>
                    {errorMessage && <ErrorMessage text={errorMessage}/>}
                    <div style={{display: "flex", alignItems: "center"}}>
                        <Checkbox checked={bookingSystem === BookingSystem.MATCHI} onChange={this.matchiCheckboxClicked}
                                  id={"matchi"}/>
                        <span style={{marginLeft: "0.5rem", marginRight: "2rem"}}>Matchi</span>
                        <Checkbox checked={bookingSystem === BookingSystem.NASMO} onChange={this.nasmoCheckboxClicked}
                                  id={"nasmo"}/>
                        <span style={{marginLeft: "0.5rem"}}>nasMo</span>
                    </div>
                    {bookingSystem === BookingSystem.MATCHI &&
                        <MatchiConfig matchi={matchi} onChange={this.matchiChanged}/>}
                    {bookingSystem === BookingSystem.NASMO && <NasmoConfig nasmo={nasmo} onChange={this.nasmoChanged}/>}
                    <button style={{padding: "0 1rem"}} onClick={this.handleSaveClick}
                            disabled={this.saveDisabled()}>Spara
                    </button>
                </div>
                <Panel heading="Namn-alias för banor">
                    <button style={{padding: "0 1rem"}} onClick={this.addAlias}>Nytt alias</button>
                    <Table>
                        <TableRow width={"25rem"} mobWidth={"40rem"}>
                            <TableText left="0" mobLeft="0" bold={true}>Banans namn i bokningssystemet</TableText>
                            <TableText left="20rem" mobLeft="30rem" bold={true}>Alias</TableText>
                        </TableRow>
                        {courtNameAliases.map((a, i) => {
                            const lastRow: boolean = courtNameAliases.length === (i + 1)
                            return this.getAlias(a, lastRow)
                        })}
                    </Table>
                </Panel>
            </BasePage>
        )
    }

    getAlias(a: CourtNameAliasDto, lastRow: boolean) {
        return (
            <TableRow key={a.courtName} width={"25rem"} mobWidth={"40rem"} lastRow={lastRow}>
                <TableText left="0" mobLeft="0">{a.courtName}</TableText>
                <TableText left="20rem" mobLeft="30rem">{a.alias}</TableText>
            </TableRow>
        )
    }

    private handleSaveClick = async () => {
        this.setState({testingCommunication: true})
        const {groupplayId} = this.props.match.params
        const {bookingSystem, matchi, nasmo} = this.state
        if (this.props.session) {
            const request: BookingSystemSettingsDto = {
                nasmo: bookingSystem === BookingSystem.NASMO ? nasmo : undefined,
                matchi: bookingSystem === BookingSystem.MATCHI ? matchi : undefined
            }
            try {
                await saveBookingsSystemSettings(request, groupplayId, this.props.session.sessionId)
                const result: TestBookingSystemDto = await testBookingSystem(groupplayId, this.props.session.sessionId)
                this.setState({
                    testingCommunication: false,
                    communicationSuccessful: true,
                    futureBookingsFound: result.futureBookingsFound,
                    loading: false
                })
            } catch (error: any) {
                console.log('error type:' + error.type)
                switch (error.type) {
                    case "COMMUNICATION_PROBLEM_WITH_BOOKINGSYSTEM":
                        this.setState({errorMessage: error.message, testingCommunication: false, loading: false})
                        break
                    default:
                        console.log("FATAL ERROR")
                        this.setState({fatalError: true})
                        break
                }
            }
        } else {
            this.setState({errorMessage: "", fatalError: true})
        }
    }

    private addAlias = () => {
        this.setState(prevState => ({
            courtNameAliases: [
                ...prevState.courtNameAliases,
                {courtName: "Banans namn i bokningssystemet", alias: "bana 1"}
            ]
        }));
    }

    private saveDisabled = (): boolean => {

        return false
    }

    private matchiCheckboxClicked = () => {
        this.setState({bookingSystem: BookingSystem.MATCHI})
    }

    private nasmoCheckboxClicked = () => {
        this.setState({bookingSystem: BookingSystem.NASMO})
    }

    private matchiChanged = (matchi: MatchiConfigDto) => {
        this.setState({matchi: matchi})
    }

    private nasmoChanged = (nasmo: NasmoConfigDto) => {
        this.setState({nasmo: nasmo})
    }
}