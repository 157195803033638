import * as React from "react"
import {MatchiConfigDto} from "../../../api/dtos"
import TextInput from "../../../components/TextInput"
import {mobile} from "../../../App"

interface Props {
    matchi: MatchiConfigDto
    onChange: (matchi : MatchiConfigDto) => any
}

interface State {
}

export default class MatchiConfig extends React.Component<Props, State> {
    render() {
        const {matchi} = this.props;
        const fontSize = mobile ? "3rem" : "1rem";
        const textMargin = mobile ? "1.5rem" : "0.5rem";
        return (

                <div style={{display: "flex", flexDirection: "column", marginTop: "2rem"}}>
                    <span style={{fontSize: fontSize, marginLeft: textMargin}}>Bokningskontots namn:</span>
                    <TextInput value={matchi.bookingUsername} onChange={this.handleBookingsUsernameChange}
                               placeHolder={"Bokningskonto"}/>
                    <span style={{fontSize: fontSize, marginLeft: textMargin}}>Admin e-post:</span>
                    <TextInput value={matchi.adminUsername} onChange={this.handleAdminUsernameChange}
                               placeHolder={"Admin e-post"}/>
                    <span style={{fontSize: fontSize, marginLeft: textMargin}}>Admin lösenord:</span>
                    <TextInput value={matchi.adminPassword} onChange={this.handleAdminPasswordChange}
                               placeHolder={"Admin lösenord"} password={true}/>
                </div>
        );
    }


    private handleBookingsUsernameChange = (currentValue: string) => {
        const {matchi} = this.props;
        this.props.onChange( {adminPassword: matchi.adminPassword, adminUsername: matchi.adminUsername, bookingUsername: currentValue});
    };

    private handleAdminUsernameChange = (currentValue: string) => {
        const {matchi} = this.props;
        this.props.onChange( {adminPassword: matchi.adminPassword, adminUsername: currentValue, bookingUsername: matchi.bookingUsername});

    };

    private handleAdminPasswordChange = (currentValue: string) => {
        const {matchi} = this.props;
        this.props.onChange( {adminPassword: currentValue, adminUsername: matchi.adminUsername, bookingUsername: matchi.bookingUsername});
    };
}